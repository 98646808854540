import React, { useEffect } from "react";
import { styled } from "goober";
import {
  useModeStore,
  useSidebarRightStore,
  useTemplateStore,
} from "../stores";
import { Dropdown } from "./Dropdown";
import { useConfig } from "../api/hooks";
import { Toggle } from "./Toggle";
import { iconMoon, iconReload, iconSun } from "./Icons";
import { SidebarHeading } from "./Atoms";
import { useSidebar } from "../hooks";
import { Button } from "./Button";
import { useCacheBuster } from "../api";
import { getMemoizedTemplate, setTemplateWithFallback } from "../utils/helpers";

const Container = styled("div", React.forwardRef)<{
  active?: number;
  background?: string;
}>`
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  padding-right: 4rem;
  padding-bottom: 4rem;
  overflow: auto;
  left: 100%;
  top: 100%;
  background: ${(p) => p.background ?? p.theme.colors.darkerBackground};
  height: 100vh;
  translate: ${(p) => (p.active ? `-100%` : "0")} 0;
  transition: translate 0.1s ease-in-out;
`;

export const SidebarRight = ({ background }: { background?: string }) => {
  const { templates } = useConfig();
  const [sidebar, setSidebar] = useSidebarRightStore();
  const [[templateId, templateTheme], setTemplate] = useTemplateStore();
  const [mode, setMode] = useModeStore();
  const sidebarRef = useSidebar(sidebar, () => setSidebar(false));
  const [busting, bust] = useCacheBuster();

  const currentTemplate = getMemoizedTemplate(templates, templateId);

  useEffect(() => {
    if (!templateId) setTemplateWithFallback(templates);
  }, [templates, templateId]);

  return (
    <Container
      active={sidebar ? 1 : 0}
      background={background}
      id="sidebar"
      ref={sidebarRef}
    >
      {templates && (
        <>
          <SidebarHeading>Layout</SidebarHeading>
          <Dropdown
            disabled={templates.length < 2}
            label="Template"
            value={templateId}
            onChange={(e) => setTemplateWithFallback(templates, e.target.value)}
            labelabove
          >
            {templates.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Dropdown>
          <Dropdown
            disabled={!currentTemplate || currentTemplate.themes.length < 2}
            label="Theme"
            value={templateTheme}
            onChange={(e) => setTemplate([templateId!, e.target.value])}
            labelabove
          >
            {currentTemplate?.themes.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Dropdown>
        </>
      )}

      <SidebarHeading>Settings</SidebarHeading>
      <Button loading={busting} icon={iconReload} onClick={() => bust()}>
        Reload
      </Button>
      <SidebarHeading>Settings</SidebarHeading>
      <Toggle
        label="Mode"
        offIcon={iconMoon}
        onIcon={iconSun}
        toggled={mode === "light"}
        onChange={(toggled) => setMode(toggled ? "light" : "dark")}
        labelabove
      />
    </Container>
  );
};
