import React from "react";
import { styled } from "goober";
import { useModeStore } from "../stores";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonEl = styled("button")<{
  loading?: number;
  mode: "dark" | "light";
  withicon?: number;
}>(
  (p) => `
  background: ${p.theme.colors.contrast};
  border-radius: ${p.theme.radius};
  outline: solid 0.2rem ${p.theme.colors.contrast};
  border: solid 1px ${p.theme.colors.ghost};
  display: inline-flex;
  padding: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;

  ${
    p.mode === "dark"
      ? `
    border-right-color: transparent;
  border-bottom-color: transparent;
    `
      : `
    border-top-color: transparent;
  border-left-color: transparent;
    `
  }

  &:hover {
    color: ${p.theme.colors.dimmed};
  }

  &:active {
    color: ${p.theme.colors.faint};
    border-color: transparent;
  }

  ${
    p.withicon &&
    `
    padding-left: 3.4rem;
    `
  }

  svg {
    position: absolute;
    left: 0;
    top: 50%;
    height: 1.8rem;
    translate: 0 -50%;
    animation: ${p.loading ? p.theme.animation.spin : "none"};

    path {
      fill: currentColor;
    }
  }

  &:disabled {
    color: ${p.theme.colors.faint};
    cursor: default;
  }
`
);

type Props = Pick<ButtonProps, "children" | "type" | "onClick"> & {
  icon?: JSX.Element;
  loading?: boolean;
};

export const Button = ({
  children,
  icon,
  loading,
  type = "button",
  ...props
}: Props) => {
  const [mode] = useModeStore();

  return (
    <ButtonEl
      {...props}
      type={type}
      disabled={loading}
      loading={loading ? 1 : 0}
      withicon={icon ? 1 : 0}
      mode={mode}
    >
      {icon}
      {children}
    </ButtonEl>
  );
};
