import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import root from "react-shadow";

const RootDiv = root.div!;

import { useApi, API } from "../api";
import { makeRender } from "../renderer";
import {
  setDocumentInfo,
  useDocStore,
  useModeStore,
  useTemplateStore,
} from "../stores";
import { useConfig } from "../api/hooks";
import { getDetailsByDoc, getMemoizedTemplate } from "../utils/helpers";
import { useTheme } from "../theme";
import { useResize } from "../hooks";

const ErrorMessage = ({ msg }: { msg: string }) => {
  const theme = useTheme();

  return (
    <div className="PROFILER--ERROR">
      <p
        dangerouslySetInnerHTML={{ __html: msg }}
        style={{
          background: theme.colors.danger,
        }}
      />
    </div>
  );
};

export const Doc = () => {
  const rootEl = useRef<HTMLDivElement>(null);
  const [mode] = useModeStore();
  const [[templateId, themeId]] = useTemplateStore();
  const { resources, templates, error: configError } = useConfig();
  const [doc] = useDocStore();
  const [error, setError] = useState<string | null>(null);

  const { compile, render } = useMemo(
    () =>
      makeRender(
        (s: string) => `${API}/${s.replace("assets/", `assets/${templateId}/`)}`
      ),
    [templateId]
  );

  const templateInfo = getMemoizedTemplate(templates, templateId);
  const themeInfo = useMemo(
    () => templateInfo && templateInfo.themes.find((t) => t.id === themeId),
    [templateInfo, themeId]
  );
  const resource = useMemo(
    () => getDetailsByDoc(doc, resources),
    [resources, doc]
  );

  // set background color
  const background = templateInfo?.app?.mode?.[mode]?.documentBgColor;
  useLayoutEffect(() => {
    if (background) document.body.style.backgroundColor = background;
  }, [background]);

  // load renderer styles, template, theme and profile -->
  const { data: rendererStyles, error: stylesError } = useApi<RendererStyles>(
    "rendererStyles",
    "/styles"
  );
  const { data: template, error: e1 } = useApi<Template>(
    "template",
    templateInfo?.url
  );
  const { data: theme, error: e2 } = useApi<Theme>("theme", themeInfo?.url);
  const { data: profile, error: e3 } = useApi<ProfileDocument>(
    "profile",
    resource?.profile.url
  );
  // <-- load renderer styles, template, theme and profile

  // compile and generate markup from template and profile data
  const markup = useMemo(
    () => (template && profile ? compile(template)(profile.data) : undefined),
    [template, profile]
  );

  useEffect(() => {
    if (profile) {
      const { data: _, ...info } = profile;
      // save document info for statusbar stats
      setDocumentInfo((state) => ({ ...state, ...info }));
    }
  }, [document, profile]);

  // error checker
  useEffect(() => {
    let e: null | string = null;

    if (configError) e ??= "config";
    if (stylesError) e ??= "styles";
    if (e1) e ??= "template";
    if (e2) e ??= "theme";
    if (e3) e ??= "profile";

    if (e) setError(`Error loading <b>${e}</b>. Try reloading the page.`);
  }, [configError, stylesError, template, e1, theme, e2, profile, e3]);

  const pageWidthRef = useRef<number>();

  useEffect(() => {
    if (rendererStyles && templateInfo && markup && rootEl.current && theme) {
      // render document in rootEl
      const nPages = render({
        styles: rendererStyles,
        markup,
        pageConfig: templateInfo.page,
        background,
        root: rootEl.current,
        theme,
      });

      // save document info for statusbar stats
      setDocumentInfo((state) => ({ ...state, nPages }));

      pageWidthRef.current =
        rootEl.current.querySelector<HTMLDivElement>(
          ".__profiler-page"
        )!.offsetWidth;
    }
  }, [rendererStyles, templateInfo, markup, rootEl.current, theme, mode]);

  // zoom document to fit page width -->
  const isZoomed = useRef(false);
  useResize(() => {
    const winWidth = window.innerWidth;

    if (!pageWidthRef.current || !rootEl.current) return;

    const pageWidth = pageWidthRef.current + 50; // offset for some page margin

    if (winWidth < pageWidth) {
      const ratio = winWidth / pageWidth;
      rootEl.current.style.zoom = `${ratio}`;
      isZoomed.current = true;
    } else if (isZoomed.current) {
      rootEl.current.style.zoom = "1";
      isZoomed.current = false;
    }
  });
  // <-- zoom document to fit page width

  return (
    <RootDiv>
      {error && <ErrorMessage msg={error} />}
      <div id="document" ref={rootEl} />
    </RootDiv>
  );
};
