import { useEffect } from "react";
import { QueryClient, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useCacheBusterStore } from "../stores";

export const API =
  process.env.API ||
  (process.env.NODE_ENV !== "production" ? "http://localhost:9000" : "");

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

let busting = false;
let bustPromise: null | Promise<void>;

export const bustCache = () => {
  if (!busting) {
    busting = true;
    bustPromise = queryClient.invalidateQueries().finally(() => {
      busting = false;
    });
  }

  // if a bust is currenctly in motion, return the same promise
  return bustPromise!;
};

export const useCacheBuster = () => {
  const [fetching, setFetching] = useCacheBusterStore();

  useEffect(() => {
    if (fetching) bustCache().finally(() => setFetching(false));
  }, [fetching]);

  return [fetching, () => setFetching(true)] as const;
};

const request = <T>(
  url: string | undefined,
  init: RequestInit = {}
): Promise<T> =>
  fetch(`${API}${url}`, {
    ...init,
    headers: { ...init.headers, cache: busting ? "reload" : "default" },
  }).then((res) => {
    if (!res.ok) throw res;

    return res.headers.get("Content-Type")?.startsWith("text/plain")
      ? res.text()
      : res.json();
  });

export const useApi = <T>(
  query: string,
  url?: string,
  config?: UseQueryOptions<T, unknown, T, Array<string>>
) =>
  useQuery({
    queryKey: [query, url ?? ""],
    queryFn: () => request<T>(url),
    enabled: Boolean(url),
    staleTime: Infinity,
    ...(config || {}),
  });
