import { useSyncExternalStore } from "react";

type Listener<T> = (state: T) => void;
type Setter<T> = (state: T) => T;

export const createStore = <T>(initialState: T) => {
  let state = initialState;

  const listeners = new Set<Listener<T>>();

  const store = {
    getState: () => state,
    setState: (newState: T | Setter<T>) => {
      state =
        typeof newState === "function"
          ? (newState as Setter<T>)(state)
          : newState;
      listeners.forEach((listener) => listener(state));

      return state;
    },
    subscribe: (listener: Listener<T>) => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
  };

  return {
    useStore: () =>
      [
        useSyncExternalStore(store.subscribe, store.getState),
        store.setState,
      ] as const,
    set: store.setState,
    get: store.getState,
  };
};

export const createPersistentStore = <T>(key: string, defaultValue: T) => {
  const storage = {
    get: () => JSON.parse(window.localStorage.getItem(key)!),
    set: (value: T) => window.localStorage.setItem(key, JSON.stringify(value)),
  };
  const store = createStore<T>(storage.get() ?? defaultValue);

  const set = (newState: T | Setter<T>) => {
    storage.set(store.set(newState));
  };

  return {
    ...store,
    useStore: () => [store.useStore()[0], set] as const,
    set,
  };
};
