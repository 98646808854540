import React, { PropsWithChildren } from "react";
import { styled } from "goober";
import { iconCaret } from "./Icons";

const Container = styled("label")<
  Pick<Props, "disabled"> & { labelabove: number }
>`
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.faint : theme.colors.text};
  display: flex;
  ${(p) => `
    align-items: ${p.labelabove ? "flex-start" : "center"};
    flex-direction: ${p.labelabove ? "column" : "row"};
    gap: ${p.labelabove ? "0.5rem" : "0.7rem"};
    `}

  .title,
  .icon {
    color: ${(p) => p.theme.colors.text};
    display: inline-block;
  }

  .title {
    font-size: 1.4rem;
  }

  .icon svg {
    width: 2rem;
    height: 2rem;
  }

  .select {
    background: ${(p) => p.theme.colors.contrast};
    border-radius: ${(p) => p.theme.radius};
    position: relative;
    display: inline-block;

    & > img {
      position: absolute;
      height: calc(100% - 8px);
      width: auto;
      left: 4px;
      top: 4px;
      filter: ${(p) => (p.disabled ? "brightness(0.7)" : "none")};
    }
  }

  select {
    appearance: none;
    border: 0;
    outline: none;
    background-color: transparent;
    padding: 0.6rem 1rem;
    padding-right: 3.7rem;
    font-size: 1.4rem;
    color: currentColor;
  }

  .arrow {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 3rem;
    pointer-events: none;

    svg {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 1rem;
      color: inherit;
    }

    path {
      fill: currentColor;
    }
  }
`;

export type Props = {
  disabled?: boolean;
  label?: string;
  labelabove?: boolean;
  icon?: JSX.Element;
  img?: string | false;
  value?: string;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
};

export const Dropdown: React.FC<PropsWithChildren<Props>> = ({
  children,
  disabled,
  label,
  labelabove,
  icon,
  img,
  onChange,
  value,
}) => (
  <Container disabled={disabled} labelabove={!icon && labelabove ? 1 : 0}>
    {label && !icon && <span className="title">{label}:</span>}
    {icon && (
      <span className="icon" title={icon ? label || "" : undefined}>
        {icon}
      </span>
    )}
    <span className="select">
      <select disabled={disabled} onChange={onChange} value={value}>
        {children}
      </select>
      {img ? <img src={img} /> : null}
      <span className="arrow">{iconCaret}</span>
    </span>
  </Container>
);
