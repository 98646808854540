import React, { useEffect, useMemo } from "react";
import { styled } from "goober";
import { Dropdown, type Props as DropdownProps } from "./Dropdown";
import { capitalize, getDetailsByDoc } from "../utils/helpers";
import { API } from "../api";
import { useDocStore, useSidebarLeftStore } from "../stores";
import { iconCaret, iconGlobe, iconLayers, iconUser, iconUsers } from "./Icons";
import { SidebarHeading } from "./Atoms";
import { useMatchMedia, useSidebar } from "../hooks";
import { useConfig } from "../api/hooks";

type FilterDropdownProps = {
  onChange: (doc: string) => void;
  options?: Profile[];
  type: keyof Profile;
  isSmallScreen: boolean;
} & Pick<DropdownProps, "icon" | "label" | "value">;

const FilterDropdown = ({
  onChange,
  options,
  type,
  icon,
  isSmallScreen,
  ...props
}: FilterDropdownProps) => {
  const img = useMemo(
    () =>
      type === "language" &&
      options?.find((o) => o.id === props.value)?.language,
    [type, options, props.value]
  );

  return (
    Boolean(options?.length) && (
      <Dropdown
        disabled={options!.length === 1}
        onChange={(e) => onChange(e.target.value)}
        img={img && `${API}/assets/${img}.png`}
        icon={isSmallScreen ? undefined : icon}
        labelabove={isSmallScreen}
        {...props}
      >
        {options!.map((o) => (
          <option key={o.id} value={o.id}>
            {capitalize(o[type as keyof Profile])}
          </option>
        ))}
      </Dropdown>
    )
  );
};

const Info = styled("button")`
  display: flex;
  font-size: 1.3rem;
  background: ${(p) => p.theme.colors.contrast};
  border-radius: ${(p) => p.theme.radius};
  text-transform: capitalize;
  cursor: pointer;

  ${(p) => p.theme.breakpoint("normal")} {
    display: none;
  }
`;

const InfoSectionSpan = styled("span")`
  display: flex;
  align-items: center;
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5rem;
  padding-right: 0;

  &.with-icon {
    padding: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &:first-child {
    max-width: 100px;
    padding-left: 0.7rem;
  }

  &:last-child:not(.with-icon) {
    padding-right: 0.7rem;
  }

  svg {
    rotate: -90deg;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }

  img {
    height: 1.6rem;
  }
`;

const Container = styled("div", React.forwardRef)<{
  background?: string;
  sidebar: number;
}>`
  display: flex;
  gap: 1.5rem;

  option.default {
    color: red;
    font-style: italic;
  }

  ${(p) => p.theme.breakpoint("normal")} {
    & > h3 {
      display: none;
    }
  }

  ${(p) => p.theme.breakpoint("small")} {
    position: absolute;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    padding-right: 4rem;
    padding-bottom: 4rem;
    overflow: auto;
    top: 100%;
    right: 100%;
    height: 100vh;
    background: ${(p) => p.background ?? p.theme.colors.darkerBackground};
    transition: translate 0.1s ease-in-out;
    translate: ${(p) => (p.sidebar ? "100%" : "0")} 0;
  }
`;

const InfoSection = ({
  children,
  isLanguage,
}: {
  children?: string;
  isLanguage?: boolean;
}) =>
  children && children !== "default" ? (
    <InfoSectionSpan className={isLanguage ? "with-icon" : undefined}>
      {iconCaret}
      {isLanguage ? <img src={`${API}/assets/${children}.png`} /> : children}
    </InfoSectionSpan>
  ) : null;

type ProfileSelectorProps = {
  sidebarBgColor?: string;
};

export const ProfileSelector = ({ sidebarBgColor }: ProfileSelectorProps) => {
  const { resources } = useConfig();
  const [doc, setDoc] = useDocStore();
  const [sidebar, setSidebar] = useSidebarLeftStore();
  const sidebarRef = useSidebar(sidebar, () => setSidebar(false));
  const isSmallScreen = useMatchMedia("small");

  useEffect(() => {
    // if there's no doc selected, select the first one
    if (!doc && resources) setDoc(resources[0]?.profiles[0]?.id);
  }, [doc, resources]);

  const selectedResource = useMemo(
    () => getDetailsByDoc(doc, resources),
    [doc, resources]
  );

  // strip duplicate keys from dropdown options, i.e we don't want to show the same language twice
  const stripDuplicates = (key: keyof Profile, list?: Profile[]) =>
    list
      ?.reduce(
        (acc, p) => {
          const existing = acc.find((x) => x[key] === p[key]);
          if (!existing) acc.push(p);

          return acc;
        },
        list.filter((x) => x.id === doc)
      )
      .toSorted((a, b) => (a[key] > b[key] ? 1 : -1));

  // get all (unique) available roles for the selected resource
  const roles = useMemo(
    () => stripDuplicates("role", selectedResource?.profiles),
    [selectedResource]
  );

  // get all (unique) available variants for the selected resource and current role
  const variants = useMemo(
    () =>
      stripDuplicates(
        "variant",
        selectedResource?.profiles.filter(
          (p) => p.role === selectedResource?.profile.role
        )
      ),
    [selectedResource]
  );

  // get all (unique) available languages for the selected resource and current variant
  const languages = useMemo(
    () =>
      stripDuplicates(
        "language",
        selectedResource?.profiles?.filter(
          (p) => p.variant === selectedResource?.profile.variant
        )
      ),
    [selectedResource]
  );

  const profile = selectedResource?.profile;

  if (!resources) return null;

  return (
    <>
      {/* show info about the selected resource and profile on small screens */}
      {selectedResource?.name && profile && (
        <Info type="button" onClick={() => setSidebar((s) => !s)}>
          <InfoSectionSpan>{selectedResource.name}</InfoSectionSpan>
          <InfoSection>{profile.role}</InfoSection>
          <InfoSection>{profile.variant}</InfoSection>
          <InfoSection isLanguage>{profile.language}</InfoSection>
        </Info>
      )}

      {/* profile selector is in top bar on large screens and in sidebar on small screens */}
      <Container
        sidebar={sidebar ? 1 : 0}
        background={sidebarBgColor}
        ref={sidebarRef}
      >
        <SidebarHeading>Profile</SidebarHeading>
        <Dropdown
          disabled={resources.length < 2}
          icon={isSmallScreen ? undefined : iconUser}
          value={selectedResource?.id}
          label="Resource"
          labelabove={isSmallScreen}
          onChange={(e) =>
            setDoc(
              resources.find((r) => r.id === e.target.value)?.profiles[0]?.id
            )
          }
        >
          {resources
            .filter((r) => r.profiles.length)
            .map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
        </Dropdown>

        {doc && (
          <>
            <FilterDropdown
              icon={iconUsers}
              onChange={setDoc}
              options={roles}
              type="role"
              value={doc}
              label="Role"
              isSmallScreen={isSmallScreen}
            />
            <FilterDropdown
              icon={iconLayers}
              onChange={setDoc}
              options={variants}
              type="variant"
              value={doc}
              label="Variant"
              isSmallScreen={isSmallScreen}
            />
            <FilterDropdown
              icon={iconGlobe}
              onChange={setDoc}
              options={languages}
              type="language"
              value={doc}
              label="Language"
              isSmallScreen={isSmallScreen}
            />
          </>
        )}
      </Container>
    </>
  );
};
