import Handlebars from "handlebars/runtime";
export default Handlebars;

/**
 * Tests to see two values are equal and evaluates block if true
 *
 * @param  {mixed} a      First value
 * @param  {mixed} b      Second value
 * @param  {html}  block  html block to evaluate if true
 */
Handlebars.registerHelper("if_eq", function (this: any, a, b, block) {
  if (a === b) {
    return block.fn(this);
  } else {
    return block.inverse(this);
  }
});

Handlebars.registerHelper(
  "levelPrinter",
  (lvl: string, type: "dots" | "line") => {
    const level = parseInt(lvl);
    const maxLevel = 10;
    let output = "";
    let count = 0;
    for (let i = 1; i <= maxLevel; i++) {
      if (i % 2 === 0 && i <= level) {
        output += `<div class="filled"></div>`;
        count++;
      } else if (i % 2 === 1 && i === level) {
        output += `<div class="half-filled"></div>`;
        count++;
      } else if (i > level && count < maxLevel / 2) {
        output += `<div class="empty"></div>`;
        count++;
      }
    }
    return new Handlebars.SafeString(
      `<div class="level--${type === "dots" ? "dot" : "line"}">${output}</div>`
    );
  }
);

Handlebars.registerHelper(
  "mergeGroupContent",
  function (this: any, groups: Group[], options) {
    const { sortBy = "", exclude = "" } = options.hash as Record<
      string,
      string
    >;
    const excludeArr = exclude.split(",");
    const list = groups
      .reduce(
        (acc, list) =>
          excludeArr.includes(list.heading!) ? acc : [...acc, ...list.content],
        [] as any[]
      )
      .sort((a, b) => (sortBy in a ? (a[sortBy] > b[sortBy] ? 1 : -1) : 0));

    let ret = "";

    for (let i = 0, j = list.length; i < j; i++) {
      ret = ret + options.fn(list[i]);
    }

    return ret;
  }
);
