import React from "react";
import { styled } from "goober";

import { useTemplateStore, useModeStore, setSidebarRight } from "../stores";
import { useCacheBuster, API } from "../api";
import { useStickyHeader } from "../hooks";

import { iconReload, iconSidebar } from "./Icons";
import { StatusBar } from "./StatusBar";
import { useConfig } from "../api/hooks";
import { SidebarRight } from "./SidebarRight";
import { ProfileSelector } from "./ProfileSelector";
import { getMemoizedTemplate } from "../utils/helpers";

const Container = styled("div", React.forwardRef)<{ background?: string }>`
  background: ${(p) => p.background ?? p.theme.colors.contrast};
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;

  @media print {
    display: none;
  }
`;

const Logo = styled("img")<{ mode: "dark" | "light" }>`
  width: 22px;
  margin-right: 1.5rem;
  filter: grayscale(1)
    ${(p) => (p.mode === "dark" ? "brightness(100)" : "brightness(0)")};

  ${(p) => p.theme.breakpoint("small")} {
    display: none;
  }
`;

const Right = styled("div")`
  margin-left: auto;
`;

const Button = styled("button")<{ spin?: 0 | 1 }>(
  (p) => `
margin: 0 0.3rem;

&:hover, &:disabled {
  opacity: 0.5;
  cursor: pointer;
}

& svg {
  width: 20px;
  height: 20px;
  margin-left: 1rem;
}

& #icon-reload {
  animation: ${p.spin ? p.theme.animation.spin : "none"};
}
`
);

// BustButton is hidden on small screens
const BustButton = styled(Button)`
  ${(p) => p.theme.breakpoint("small")} {
    display: none;
  }
`;

export const Settings = () => {
  const { templates } = useConfig();
  const stickyRef = useStickyHeader();
  const [[templateId]] = useTemplateStore();
  const [mode] = useModeStore();
  const [busting, bustCache] = useCacheBuster();

  // since this is memoized we don't need to use useMemo
  const currentTemplate = getMemoizedTemplate(templates, templateId);

  const { settingsBgColor, sidebarBgColor } =
    currentTemplate?.app?.mode?.[mode] ?? {};

  return (
    <>
      <Container ref={stickyRef} id="settings" background={settingsBgColor}>
        <Logo src={`${API}/assets/logo.svg`} mode={mode} />
        <ProfileSelector sidebarBgColor={sidebarBgColor} />

        <Right>
          <BustButton
            disabled={Boolean(busting)}
            onClick={() => bustCache()}
            spin={busting ? 1 : 0}
          >
            {iconReload}
          </BustButton>
          <Button onClick={() => setSidebarRight((s) => !s)}>
            {iconSidebar}
          </Button>
        </Right>
        <SidebarRight background={sidebarBgColor} />
      </Container>
      <StatusBar background={settingsBgColor}></StatusBar>
    </>
  );
};
