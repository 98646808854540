import Handlebars from "./helpers";
import { getMesearements } from "../utils/helpers";

type RenderOptions = {
  styles?: string;
  background?: string;
  pageConfig: TemplateConfig["page"];
  head?: HTMLHeadElement;
  root: HTMLElement;
  markup: string;
  theme: string;
};

type Results = {
  render(options: RenderOptions): number;
  compile(template: string): ReturnType<typeof Handlebars.compile>;
};

interface CreateStyle {
  (id: string, styles: string): HTMLStyleElement;
  (id: string, styles?: string): HTMLStyleElement | undefined;
}

const addPageId = (page: Element, id: number) =>
  page.classList.add(`__profiler-page--${id}`);

const getPageId = (page: Element) =>
  [...page.classList.values()].reduce(
    (acc, cl) => Number(cl.replace("__profiler-page--", "")) || acc,
    -1
  );

const newPage = (clone: Element, cloneId: number) => {
  const next = clone.cloneNode() as Element;

  next.classList.remove(`__profiler-page--${cloneId}`);
  addPageId(next, cloneId + 1);

  return next;
};

const isHeaderOrFooter = (el: Element) =>
  [...el.classList].some(
    (c) => c === "PROFILER--PAGE-HEADER" || c === "PROFILER--PAGE-FOOTER"
  );

const isPageBreak = (el: Element) =>
  [...el.classList].includes("PROFILER--PAGE-BREAK");

export const makeRender: (resolveAssetUrl: (s: string) => string) => Results = (
  resolveAssetUrl
) => {
  let firstPageHeader: HTMLElement | null;
  let pageHeader: HTMLElement | null;
  let pageFooter: HTMLElement | null;

  const handleCSSAssets = (t?: string) =>
    t ? t.replace(/(?<=url\(\")(?!http).+(?=\")/g, resolveAssetUrl) : "";

  const handleHTMLAssets = (t?: string) =>
    t ? t.replace(/(?<=\")\.?\/?assets.+?(?=(\\\"))/g, resolveAssetUrl) : "";

  const createStyleTag: CreateStyle = (id: any, styles?: any): any => {
    if (!styles) return undefined;

    const el = document.createElement("style");

    el.id = id;
    el.innerHTML = handleCSSAssets(styles);

    return el;
  };

  interface DistributePagesOptions {
    root: HTMLElement;
    pagePaddingTop: number;
    firstPagePaddingTop: number;
  }
  // prevent content from overlapping page, rather moving the content to the next (new) page
  const distributeContent = (o: DistributePagesOptions) => {
    const pages = o.root.querySelectorAll<HTMLElement>(".__profiler-page");
    let rerun = false;

    pages.forEach((el, ix) => {
      el.style.paddingTop = `${
        ix ? o.pagePaddingTop : o.firstPagePaddingTop
      }px`;

      const { height: pageHeight, top } = el.getBoundingClientRect();
      const { paddingBottom: pb, paddingTop: pt } = getComputedStyle(el);
      const paddingBottom = parseFloat(pb);
      const paddingTop = parseFloat(pt);
      const bottom = pageHeight + top - paddingBottom;
      const availableSpace = pageHeight - paddingTop - paddingBottom;
      const id = ix + 1;

      addPageId(el, id);

      [...el.children].reverse().forEach((child) => {
        // don't measure and distribute content based on page footer
        if (isHeaderOrFooter(child)) return;

        const { top, height } = child.getBoundingClientRect();
        const childBottom = height + top;

        if (bottom < childBottom) {
          // if element is longer than the available page, wrap it in overflow: hidden and make it ugly
          if (!isPageBreak(child) && height > availableSpace) {
            child.classList.add("__profiler-el-error");
            (child as HTMLElement).style.height = `${availableSpace}px`;
          }

          const nextPage = [
            ...o.root.querySelectorAll(".__profiler-page"),
          ].find((p) => getPageId(p) === id + 1);

          if (nextPage) nextPage.prepend(child);
          else {
            const nextPage = newPage(el, id);

            nextPage.appendChild(child);

            if (pageHeader) nextPage.appendChild(pageHeader.cloneNode(true));
            if (pageFooter) nextPage.appendChild(pageFooter.cloneNode(true));

            o.root.appendChild(nextPage);
          }

          rerun = true;
        }
      });
    });

    if (rerun) distributeContent(o);
  };

  const clean = (root: Element, head?: HTMLHeadElement) => {
    root.querySelectorAll(".__profiler-page").forEach((el) => el.remove());
    (head ?? root).querySelector(`#__profiler-styles`)?.remove();
    (head ?? root).querySelector(`#__profiler-theme`)?.remove();
  };

  const processPages = (
    {
      size = "A4",
      orientation = "portrait",
      marginVertical = 15,
      marginHorizontal = 20,
    }: TemplateConfig["page"] = {},
    markup: string,
    root: HTMLElement
  ) => {
    root.style.position = "relative";
    root.classList.add("PROFILER--ROOT");

    const page = document.createElement("div");

    page.classList.add(
      "__profiler-page",
      `__profiler-${size}`,
      `__profiler-${orientation}`
    );
    page.style.padding = `${marginVertical}mm ${marginHorizontal}mm`;
    page.innerHTML = markup;

    root.appendChild(page);

    firstPageHeader = page.querySelector(".PROFILER--FIRST-PAGE-HEADER");
    pageHeader = page.querySelector(".PROFILER--PAGE-HEADER");
    pageFooter = page.querySelector(".PROFILER--PAGE-FOOTER");

    const pagePaddingTop = pageHeader ? getMesearements(pageHeader).height : 0;
    const firstPagePaddingTop = firstPageHeader
      ? getMesearements(firstPageHeader).height
      : pagePaddingTop;

    if (pageFooter)
      page.style.paddingBottom = `${getMesearements(pageFooter).height}px`;

    distributeContent({ root, pagePaddingTop, firstPagePaddingTop });

    // move page header and footer to bottom so it's the same as the generated pages
    if (firstPageHeader) {
      page.appendChild(firstPageHeader);
      pageHeader?.remove();
    } else if (pageHeader) page.appendChild(pageHeader);
    if (pageFooter) page.appendChild(pageFooter);
  };

  return {
    compile: (template) =>
      Handlebars.template(eval("(" + handleHTMLAssets(template) + ")")),
    render: ({ styles, background, pageConfig, root, head, markup, theme }) => {
      clean(root, head);

      const rendererStyles = createStyleTag("__profiler-styles", styles);
      const themeStyles = createStyleTag("__profiler-theme", theme);

      if (rendererStyles) (head ?? root).appendChild(rendererStyles);

      (head ?? root).appendChild(themeStyles);

      root.style.backgroundColor = background ?? "inherit";

      processPages(pageConfig, markup, root);

      return root.querySelectorAll(".__profiler-page").length;
    },
  };
};
