import React, { useEffect, useState } from "react";
import { styled } from "goober";

import { useIsFetching } from "@tanstack/react-query";

const Container = styled("div")`
  position: fixed;
  z-index: 100;
  height: 2px;
  width: 100%;
  display: flex;
  top: 0;

  @media print {
    display: none;
  }
`;

const Bar = styled("div", React.forwardRef)`
  height: 100%;
  width: 0;
  opacity: 0;
  background: ${(p) => p.theme.colors.accent};
`;

const TRANSITION_TIME = 400;
const BASE_TRANSITION = {
  transition: `all ${TRANSITION_TIME}ms ease-in-out`,
};
// transition for the end state, make a smooth delayed fade out
const END_TRANSITION = {
  transition: `width ${TRANSITION_TIME}ms ease-in-out, opacity ${
    TRANSITION_TIME * 2
  }ms ${TRANSITION_TIME}ms`,
};

export const Loader = () => {
  const count = useIsFetching(); // returns the number of queries currently in flight
  const [active, setActive] = useState(false);

  const progress = count ? Math.round((1 - count / (count + 3)) * 100) : 100;

  let style: React.CSSProperties = {
    ...BASE_TRANSITION,
    opacity: 1,
    width: `${progress}%`,
  };

  // no active queries, set the loader to 100% or 0 when inactive
  if (!count) {
    style = { ...END_TRANSITION, opacity: 0, width: active ? "100%" : 0 };
  }

  useEffect(() => {
    let t: number;

    // if the loader is inactive and there is active queries, set it to active
    if (count && !active) setActive(true);

    if (!count && active) {
      // after finishing, set the loader to inactive when fade-out is done
      t = window.setTimeout(() => setActive(false), TRANSITION_TIME * 3);
    }

    return () => window.clearTimeout(t);
  }, [count, active]);

  return (
    <Container>
      <Bar style={style} />
    </Container>
  );
};
