import { styled } from "goober";

type SpacerProps = { type: "ver" | "hor"; size?: string };

export const Spacer = styled("div")<SpacerProps>(({ type, size = "2rem" }) =>
  type === "hor"
    ? `
        display: inline-block;
        width: ${size};
      `
    : `
        height: ${size};
      `
);

export const SidebarHeading = styled("h3")`
  font-size: 1.4rem;
  color: ${(p) => p.theme.colors.faint};
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  margin-top: 2.5rem;
  text-transform: uppercase;

  &:first-child {
    margin-top: 0;
  }
`;
