import { useApi } from ".";

export const useConfig = () => {
  const { data, error } = useApi<Config>("config", "/config");

  // make it possible to directly destructure templates and resources from useConfig
  const { templates, resources } = data ?? {};

  return { templates, resources, error };
};
