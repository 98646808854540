import React, { useMemo } from "react";
import { styled } from "goober";

import { useDocStore, useDocumentInfoStore, useTemplateStore } from "../stores";

import { API } from "../api";
import { DownloadButton } from "./DownloadButton";
import { str2filename } from "../utils/helpers";
import { useConfig } from "../api/hooks";

type Props = {
  background?: string;
};

const Container = styled("div")<Props>`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  background: ${(p) => p.background ?? p.theme.colors.contrast};
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  font-size: 1.4rem;
  color: ${(p) => p.theme.colors.dimmed};

  @media print {
    display: none;
  }
`;

export const StatusBar = (p: Props) => {
  const { resources } = useConfig();
  const [[template, templateTheme]] = useTemplateStore();
  const [{ chars, words, nPages }] = useDocumentInfoStore();
  const [doc] = useDocStore();
  const url = `${API}/download/pdf/${doc}?template=${template}&theme=${templateTheme}`;

  const selectedProfileName = useMemo(
    () =>
      resources?.reduce<string | undefined>(
        (acc, r) => (r.profiles.find((p) => p.id === doc) ? r.name : acc),
        undefined
      ),
    [doc, resources]
  );

  return (
    <Container {...p}>
      <span>
        <em>{chars}</em> characters, <em>{words}</em> words,{" "}
        <em>{nPages} pages</em>
      </span>
      <DownloadButton
        color={p.background}
        url={url}
        filename={`${str2filename(selectedProfileName ?? "pdf")}.pdf`}
      />
    </Container>
  );
};
