import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { createGlobalStyles } from "goober/global";
import { styled } from "goober";
import "the-new-css-reset/css/reset.css";

import { ThemeProvider } from "./theme";
import { queryClient } from "./api";

import { Loader } from "./components/Loader";
import { Settings } from "./components/Settings";
import { Doc } from "./components/Doc";

const BaseStyles = createGlobalStyles`
  html {
    font-size: 55%;
  }

  html, #app {
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
    font: inherit;
  }

  body {
      background: ${(p) => p.theme.colors.background};
      color: ${(p) => p.theme.colors.text};
      height: 100%;
  }

  #app {
    height: 100%;
    max-height: 100%;
  }

  @property --download-progress {
    syntax: '<percentage>';
    inherits: false;
    initial-value: 0%;
  }

  @media print {
    @page {
      margin: 0;
    }

    #app {
      margin: 0;
    }
  }
` as React.FunctionComponent;

if (process.env.NODE_ENV !== "production") {
  // force watching of template files
  // @ts-ignore
  import("../templates/**/*.*");
  let updating = false;

  const update = () => {
    if (updating) return;
    updating = true;

    queryClient.invalidateQueries();

    setTimeout(() => {
      updating = false;
    }, 500);
  };

  const observer = new MutationObserver(update);

  observer.observe(document.body!, {
    childList: true,
  });
}

const Container = styled("div")`
  position: relative;
`;

const printVersion = (v: string) =>
  console.log("%cProfiler", "font-weight: bold", `v${v}`);

const App: React.FC = () => {
  useEffect(() => {
    const version = process.env.PROFILER_VERSION;

    if (version) printVersion(version);
    else if (process.env.NODE_ENV === "development")
      import("../package.json").then((pkg) => printVersion(pkg.version));
  }, []);

  return (
    <ThemeProvider>
      <BaseStyles />
      <Container>
        <Loader />
        <Settings />
        <Doc />
      </Container>
    </ThemeProvider>
  );
};

const root = createRoot(document.getElementById("app")!);

root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);
