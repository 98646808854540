import React, { createContext, PropsWithChildren, useContext } from "react";
import { keyframes, setup } from "goober";
import { useModeStore, defaultMode } from "./stores";

const spinFrames = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const breakpoint = (bp: "small" | "normal") =>
  `@media (${bp === "small" ? `max-width: 767px` : `min-width: 768px`})`;

const common = {
  radius: "3px",
  breakpoint,
  animation: {
    spin: `${spinFrames} linear 0.6s infinite`,
  },
};

const light = {
  ...common,
  colors: {
    background: "#eee",
    darkerBackground: "rgba(221, 221, 221, 0.95)",
    contrast: "rgba(255,255,255,.4)",
    text: "#222",
    dimmed: "rgba(0,0,0,.6)",
    faint: "rgba(0,0,0,.4)",
    ghost: "rgba(0,0,0,.2)",
    primary: "#ff9e27",
    accent: "#4eaace",
    danger: "#ff4d4f",
  },
};
const dark: Theme = {
  ...common,
  colors: {
    background: "#57544e",
    darkerBackground: "rgba(60, 59, 59, 0.95)",
    contrast: "rgba(0,0,0,.4)",
    text: "#fff",
    dimmed: "rgba(255,255,255,.6)",
    faint: "rgba(255,255,255,.4)",
    ghost: "rgba(255,255,255,.2)",
    primary: "#ff9e27",
    accent: "#70deff",
    danger: "#c2292b",
  },
};

export type Theme = typeof light;

export const ThemeContext = createContext(
  defaultMode === "dark" ? dark : light
);
export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [mode] = useModeStore();

  return (
    <ThemeContext.Provider value={mode === "dark" ? dark : light}>
      {children}
    </ThemeContext.Provider>
  );
};

setup(React.createElement, undefined, useTheme);
