import { shallowEqualArrays } from "shallow-equal";
import { getCurrentTemplate, setTemplate } from "../stores";

export const toN = (str: string) => Math.round(window.parseFloat(str));

export const getMesearements = (el: Element, withMargin = true) => {
  const style = window.getComputedStyle(el);
  const margin = withMargin && {
    ver: toN(style.marginBottom) + toN(style.marginTop),
    hor: toN(style.marginLeft) + toN(style.marginRight),
  };

  return {
    height: el.clientHeight + (margin ? margin.ver : 0),
    width: el.clientWidth + (margin ? margin.hor : 0),
  };
};

export const str2filename = (s: string) => s.toLowerCase().replace(" ", ".");

export const getDetailsByDoc = (doc?: string, resources?: Resource[]) =>
  resources?.reduce<
    | (Omit<Resource, "profiles"> & { profile: Profile; profiles: Profile[] })
    | undefined
  >((acc, { id, name, profiles }) => {
    const profile = profiles.find((p) => p.id === doc);

    return profile
      ? {
          id,
          name,
          profile,
          profiles,
        }
      : acc;
  }, undefined);

export const unique = <T>(list?: T[]) => list && [...new Set(list)];
export const uniqueBy = <T>(key: keyof T, list?: T[]) =>
  list?.reduce<T[]>((acc, p) => {
    const existing = acc.find((x) => x[key] === p[key]);
    if (!existing) acc.push(p);

    return acc;
  }, []);

export const capitalize = (s: string) =>
  s?.charAt(0).toUpperCase() + s?.slice(1);

const memoCache = new Map<string, { deps: readonly any[]; result: any }>();

const shallowCompare = (
  a?: readonly any[],
  b?: readonly any[]
): a is NonNullable<typeof a> =>
  Boolean(a && b) && shallowEqualArrays(a as any[], b as any[]);

export const memo = <T, R>(exec: () => R, deps: readonly T[]): R => {
  const fStr = exec.toString();
  const cached = memoCache.get(fStr);

  if (shallowCompare(cached?.deps, deps)) return cached.result;

  const result = exec();

  if (result != null) memoCache.set(fStr, { deps, result });

  return result;
};

export const getMemoizedTemplate = (
  templates?: TemplatesInfo,
  templateId?: string
) =>
  memo(() => {
    const id = templateId || getCurrentTemplate()[0];

    if (!id) return undefined;

    return templates?.find((t) => t.id === id);
  }, [templates, templateId]);

export const setTemplateWithFallback = (
  templates?: TemplatesInfo,
  templateId?: string
) => {
  const templateInfo = getMemoizedTemplate(templates, templateId);

  if (templateInfo && templateId) {
    const defaultTheme = templateInfo.themes[0]?.id;

    if (defaultTheme) {
      setTemplate([templateId, defaultTheme]);
    } else
      throw Error(
        `Template ${templateInfo.name} doesn't haev any available themes`
      );
  } else {
    const fallbackTemplate = templates?.[0];
    const fallbackTheme = fallbackTemplate?.themes[0];

    if (fallbackTemplate && fallbackTheme) {
      setTemplate([fallbackTemplate.id, fallbackTheme.id]);
    }
  }
};
