import React from "react";
import { styled } from "goober";

const Container = styled("label")<{ labelabove: number }>`
  position: relative;
  font-size: 0;
  cursor: pointer;
  display: inline-flex;
  ${(p) => `
    align-items: ${p.labelabove ? "flex-start" : "center"};
    flex-direction: ${p.labelabove ? "column" : "row"};
    gap: ${p.labelabove ? "0.5rem" : "0.7rem"};
    `}

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + .toggle-slider {
      svg {
        opacity: 1;
      }

      &:before {
        translate: calc(100% + 0.8rem);
        background: ${(p) => p.theme.colors.accent};
      }
    }
  }

  svg {
    height: 2.2rem;
    width: auto;
  }

  .toggle-label {
    font-size: 1.4rem;
  }

  .toggle-slider {
    position: relative;
    display: inline-flex;
    padding: 0.4rem;
    gap: 0.8rem;
    background: ${(p) => p.theme.colors.contrast};
    border-radius: ${(p) => p.theme.radius};

    &:before {
      position: absolute;
      z-index: 1;
      content: "0";
      background: ${(p) => p.theme.colors.background};
      border-radius: ${(p) => p.theme.radius};
      height: calc(100% - 0.8rem);
      width: calc(50% - 0.8rem);
      top: 0.4rem;
      left: 0.4rem;
      transition:
        translate 0.1s ease-in-out,
        background-color 0.25s ease-in;
    }
  }

  .toggle-off {
    opacity: 0.5;
  }
`;

type Props = {
  label?: string;
  labelabove?: boolean;
  offIcon: JSX.Element;
  onIcon: JSX.Element;
  toggled?: boolean;
  onChange?: (toggled: boolean) => void;
};

export const Toggle = ({
  label,
  labelabove,
  offIcon,
  onIcon,
  toggled,
  onChange,
}: Props) => (
  <Container labelabove={labelabove ? 1 : 0}>
    {label ? <span className="toggle-label">{label}:</span> : null}
    <input
      type="checkbox"
      checked={toggled}
      onChange={(e) => onChange?.(e.target.checked)}
    />
    <span className="toggle-slider">
      <span className="toggle-on">{onIcon}</span>
      <span className="toggle-off">{offIcon}</span>
    </span>
  </Container>
);
