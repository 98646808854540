import { useEffect, useRef, useState } from "react";
import { breakpoint } from "./theme";
import { useSidebarLeftStore } from "./stores";
import { useConfig } from "./api/hooks";
import { getMemoizedTemplate } from "./utils/helpers";

export const useStickyHeader = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const el = ref.current;
    let resizeTimer: number;
    let size = el.getBoundingClientRect();
    let prevY = window.scrollY;
    let y = prevY;
    let elY = 0;

    const loop = () => {
      y = Math.round(window.scrollY);
      if (y === prevY) return;

      const dir = prevY > y ? "up" : "down";

      if (dir === "down" && elY <= size.height && y > 0) {
        elY = elY + (y - prevY);
        el.style.transform = `translateY(-${Math.round(elY)}px)`;
      }

      if (dir === "up" && elY >= 0) {
        if (elY > size.height) elY = size.height;

        elY = elY - (prevY - y);
        el.style.transform = `translateY(-${Math.round(elY > 0 ? elY : 0)}px)`;
      }

      prevY = y;

      window.requestAnimationFrame(loop);
    };

    const scroll = () => window.requestAnimationFrame(loop);

    const getRect = () => {
      window.clearTimeout(resizeTimer);
      resizeTimer = window.setTimeout(() => {
        size = el.getBoundingClientRect();
      }, 250);
    };

    window.addEventListener("scroll", scroll);
    window.addEventListener("resize", getRect);

    const observer = new MutationObserver(() => {
      getRect();
    });

    observer.observe(el, { subtree: true, childList: true });

    return () => {
      window.removeEventListener("scroll", scroll);
      window.removeEventListener("resize", getRect);
      window.clearTimeout(resizeTimer);
      observer.disconnect();
    };
  }, []);

  return ref;
};

export const useSidebar = <T extends HTMLElement = HTMLDivElement>(
  active: boolean,
  close: () => void
) => {
  const ref = useRef<T>(null);
  const cb = useRef<(...agrs: any[]) => void>();

  cb.current = close;

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      const t = e.target as Node;
      if (!(t && ref.current?.contains(t))) cb.current?.();
    };

    if (active) setTimeout(() => document.addEventListener("click", listener));

    return () => {
      document.removeEventListener("click", listener);
    };
  }, [active]);

  return ref;
};

export const useMatchMedia = (...args: Parameters<typeof breakpoint>) => {
  const [bp] = args;
  const getMql = () => window.matchMedia(breakpoint(bp).replace("@media ", ""));

  const [matches, setMatches] = useState(() => getMql().matches);
  const [, setSidebar] = useSidebarLeftStore();

  useEffect(() => {
    const mql = window.matchMedia(breakpoint(bp).replace("@media ", ""));
    const listener = ({ matches }: MediaQueryListEvent) => {
      setMatches(matches);
      if (!matches) setSidebar(false);
    };

    mql.addEventListener("change", listener);

    return () => {
      mql.removeEventListener("change", listener);
    };
  }, [bp]);

  return matches;
};

export const useTemplateGetter = () => {
  const { templates } = useConfig();

  return (customId?: string) => getMemoizedTemplate(templates, customId);
};

export const useResize = (cb: (e?: UIEvent) => void) => {
  const f = useRef(cb);

  f.current = cb;

  useEffect(() => {
    f.current();

    const listener = (e: UIEvent) => f.current(e);

    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [cb]);
};
