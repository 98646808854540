import { createStore, createPersistentStore } from "../utils/store";

type DocumentInfoState = {
  nPages: number;
} & Omit<ProfileDocument, "data">;

export const { useStore: useDocumentInfoStore, set: setDocumentInfo } =
  createStore<DocumentInfoState>({ chars: 0, words: 0, nPages: 0 });
export const { useStore: useSidebarRightStore, set: setSidebarRight } =
  createStore(false);
export const { useStore: useSidebarLeftStore, set: setSidebarLeft } =
  createStore(false);
export const { useStore: useCacheBusterStore, set: setCacheBuster } =
  createStore(false);

export const {
  useStore: useTemplateStore,
  set: setTemplate,
  get: getCurrentTemplate,
} = createPersistentStore<[string, string] | []>("ProfilerTemplate", []);

export const { useStore: useDocStore, set: setDoc } = createPersistentStore<
  string | undefined
>("ProfilerProfile", undefined);

export const defaultMode =
  JSON.parse(window.localStorage.getItem("ProfilerMode")!) ??
  (window.matchMedia?.("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light");

export const { useStore: useModeStore, set: setMode } = createPersistentStore<
  "dark" | "light"
>("ProfilerMode", defaultMode);
